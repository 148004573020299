import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { FaLandmark, FaPhoneVolume } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { BiLogoFacebookCircle } from "react-icons/bi";
import Imgxing from '../../assets/xingImg.png'
import Imglinkidin from '../../assets/Linkdin.png'
import Imgyoutube from '../../assets/youtube.png'
import Imgkaggle from '../../assets/kaggleImg.png'
import Imgfacebook from '../../assets/Facebook.png'
import { Link } from 'react-router-dom';
import './HfFooter.css'

import { useTranslation } from 'react-i18next';
const HfFooter = () => {
  const { t } = useTranslation();
  return (
    <footer className='hfFooter-wrapper' id='contact-us-section'>
      <Container className='hfFooter-container'>
        <Row className="justify-content-center " style={{ marginLeft: 'auto' }}>
          <Col sm={12} lg={4} >
            <div className='footer-column-div'>
              <b>{t("How to reach us")}</b>
              <ul>
                <li><a href=" https://www.google.com/maps/search/Hansa-Flex+Thailand+15%2F288+Moo+11,+Soi+Nawamin+21,+Klong+Kum+Subdistrict,+Bueng+Kum+District,+Bangkok+10230/@13.8159602,100.6496722,15.3z?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer" className="footer-link"><FaLandmark />{t("15/288 Moo11, Soi Nuanchan21, Klongkhum, Buengkum Bangkok 10230")}</a></li>
                <li><a href="tel: ++66 (0) 2 944 6944 " className="footer-link"><FaPhoneVolume />  {"++66 (0) 2 944 6944 "}</a></li>
                <li><a href="mailto:thn@hansa-flex.com" className="footer-link"><MdOutlineEmail />{"thn@hansa-flex.com"}</a></li>
              </ul>
            </div>
          </Col>

          {/* <Col sm={12} lg={3} >
            <div className='footer-column-div'>
              <b>News and events </b>
              <ul>
                <li><a href="tel:+971 (0) 800-42672353" className="footer-link"><MdOutlineEmail /> https://www.hansa-flex.be/subsidiaries.html</a></li>
              </ul>
            </div>
          </Col> */}

          <Col sm={12} lg={4}>
            <div className='footer-column-div'>
              <b>{t("About HANSA-FLEX")}</b>
              <ul>
                <li><Link to="/Careers" className="footer-link">{t("Careers")}</Link></li>
                <li><Link to="/certificates" className="footer-link">{t("Certificates")}</Link></li>
                <li> <a href="https://www.hansa-flex.de/en/company/" target="_blank" rel="noopener noreferrer" className="footer-link">{t("More About HANSA-FLEX Group")}</a></li>
                {/* <li><Link to="/careers" className="footer-link">Careers</Link></li> */}
              </ul>
            </div>
          </Col>


          {/* <Col xs={4} lg={3}>
          <div className='footer-column-div'>
          <h>Service</h>
          <ul>
                <li>Mobile rapid hydraulic service</li>
                <li>Fluid management</li>
                <li>Assembly and installation</li>
                <li>Hydarulic cylinder repair</li>
                <li>Preventive maintenance</li>
            </ul>
          </div>
          </Col> */}

          <Col sm={12} lg={4}>
            <div className='footer-column-div'>
              <b>{t("Popular Categories")}</b>
              <ul>
                <li><Link to="/hoses-and-hose-lines" className="footer-link">{t("Products")}</Link></li>
                <a href="thn@hansa-flex.com" target="_blank" rel="noopener noreferrer" className="footer-link">{t("X-CODE Manager")}</a>

              </ul>
            </div>
          </Col>

        </Row>

        {/* <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <h>Follow us</h>
          </Col>
        </Row> */}


        <Row className="custom-3row ">

          {/*<Col className="circle-col"><div><img src={Imgkaggle} alt="" /></div></Col>
          <Col className="circle-col"><div><img src={Imgxing} alt="" /></div></Col> */}
          {/* <Col ><div className="circle-col"><a href="https://www.linkedin.com/company/hansaflexbelgium/" target="_blank" rel="noopener noreferrer"><div>< img src={Imglinkidin} alt="" /></div></a></div></Col>
          <Col ><div className="circle-col"><a href="https://www.xing.com/pages/hansa-flexag" target="_blank" rel="noopener noreferrer"><div>< img src={Imgxing} alt="" /></div></a></div></Col>
          <Col ><div className="circle-col"><a href="https://www.kununu.com/de/hansa-flex" target="_blank" rel="noopener noreferrer"><div>< img src={Imgkaggle} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://www.youtube.com/@HANSAFLEXAG" target="_blank" rel="noopener noreferrer"><div><img src={Imgyoutube} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://www.facebook.com/profile.php?id=61562957267236"><div><img src={Imgfacebook} alt="" /></div></a></div></Col> */}

        </Row>

        <Row className="line-row">
          <Col className="line-col"></Col>
        </Row>

        <div className='HfFooter-link-div'>
          <a className="hf-footer-link" href="">{t("© Copyright All Rights Reserved")}</a><span>  |  </span>
          <Link to="/hansa-flex-thailand-privacy-policy" className="footer-link">{t("Privacy Policy")}</Link><span>  |  </span>
          <Link to="/disclaimer" className="footer-link">{t("Disclaimer")}</Link>

          {/*<a className="hf-footer-link" href="">GT&C</a><span>  |  </span>
          <a className="hf-footer-link" href="">Purchasing and logistics</a><span>  |  </span>
        <a className="hf-footer-link" href="">Data protection</a>*/}

        </div>



      </Container>
    </footer >

  );
};

export default HfFooter;
