import React, { useEffect } from 'react';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import TextCaption from '../Components/TextCaption/TextCaption';

function PrivayPolicyPage() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    return (
        <div>
            <SectionHeader title={"Privacy Policy"} titleSize={"2.5rem"} paragraph={"At HANSA-FLEX Hydraulics Thailand, we are committed to protecting the privacy and confidentiality of personal information collected from users of this website. This privacy policy outlines how we collect, use, disclose, and safeguard your information in compliance with Thailand's privacy laws, including the Personal Data Protection Act B.E. 2562 (2019) ('PDPA')."} />


            <TextCaption title={"Information Collection:"} paragraph={"We may collect personal information such as name, email address, phone number, and company name when voluntarily submitted by users through forms on this website. We may also collect non-personal information such as browser type, IP address, and operating system automatically through cookies and other tracking technologies."} />
            <TextCaption title={"Purpose of Collection:"} paragraph={"We collect personal information for purposes such as responding to inquiries, providing requested services, processing orders, improving our website and services, and sending marketing communications (with opt-out options provided). We will only use or disclose personal information for the purposes for which it was collected unless consent is obtained or as required by law."} />
            <TextCaption title={"Consent: "} paragraph={"By providing us with your personal information, you consent to the collection, use, and disclosure of that information as described in this privacy policy. You may withdraw consent at any time, subject to legal or contractual restrictions, by contacting us using the information provided below."} />
            <TextCaption title={"Disclosure of Information:"} paragraph={"We may disclose personal information to our affiliates, service providers, or other third parties as necessary to fulfill the purposes for which it was collected or as required by law. We take reasonable steps to ensure that third parties adhere to privacy and security standards comparable to ours."} />
            <TextCaption title={"Data Security:"} paragraph={"We implement appropriate technical, physical, and organizational measures to safeguard the confidentiality, integrity, and availability of your information. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security."} />
            <TextCaption title={"Data Transfer:"} paragraph={"Personal information collected by HANSA-FLEX Hydraulics Thailand may be stored and processed in Thailand or other countries where our affiliates or service providers are located. By using our website or providing your personal information, you consent to the transfer of information to countries outside your country of residence, which may have different data protection laws."} />
            <TextCaption title={"Data Retention:"} paragraph={"We retain personal information for as long as necessary to fulfill the purposes outlined in this privacy policy or as required by law. We will securely delete or anonymize personal information no longer needed."} />
            <TextCaption title={"Access and Correction:"} paragraph={"You have the right to access, correct, update, or delete your personal information held by HANSA-FLEX Hydraulics Thailand. To exercise these rights or inquire about our privacy practices, please contact us using the information provided below."} />
            <TextCaption title={"Children's Privacy:"} paragraph={"This website is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children. If you believe that a child has provided us with personal information, please contact us and we will take appropriate steps to delete such information."} />
            <TextCaption title={"Changes to Policy:"} paragraph={"We reserve the right to update or revise this privacy policy at any time to reflect changes in our practices, legal requirements, or technological advancements. We will post the revised policy on this website with the effective date indicated. Your continued use of this website after any changes indicates your acceptance of the updated policy."} />
            <TextCaption title={"Compliance with Laws:"} paragraph={"We are committed to complying with all applicable laws and regulations governing the collection, use, and protection of personal information, including the Personal Data Protection Act B.E. 2562 (2019) and other relevant privacy legislation in Thailand."} />
            <TextCaption title={"Contact Information:"} paragraph={"If you have any questions or concerns regarding this privacy policy or if you wish to exercise your rights or contact our Privacy Officer, please contact us at: thn@hansa-flex.com"} />
            <br /><br />
        </div>
    );
}

export default PrivayPolicyPage;