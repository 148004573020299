import React from 'react';
import './ContactUsCard.css'; // Import CSS file
import { useTranslation } from 'react-i18next';

const ContactUsCard = ({ units }) => {
    const { t } = useTranslation();
    return (
        <div className="cards-container">
            {units.map((unit, index) => (
                <div
                    key={index}
                    className="contact-card"
                    style={{
                        backgroundImage: `url(${unit.image})`,
                    }}
                >
                    <div className="content">
                        <h2 className="unit-name">{t(unit.name)}</h2>
                        {unit.description && <h6 className="description">{t(unit.description)}</h6>}

                        <div className="info">
                            <div>
                                <p className="label">{t("ADDRESS")}:</p>
                                {/* <p className="text">{unit.location}</p> */}
                                <p>
                                    <a
                                        className="text"
                                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent("Hansa-Flex Thailand " + unit.location)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t(unit.location)}
                                    </a>
                                </p>
                            </div>
                            <div>
                                <p className="label">{t("TELEPHONE")}:</p>
                                {/* <p className="text">{unit.telephone}</p> */}
                                <p>
                                    <a
                                        className="text"
                                        href={`tel:${unit.telephone.replace(/\s+/g, '')}`}
                                    >
                                        {unit.telephone}
                                    </a>
                                </p>
                            </div>
                            {unit.mail && (
                                <div>
                                    <p className="label">{t("E-MAIL")}:</p>
                                    {/* <p className="text">{unit.mail}</p> */}
                                    <a
                                        className="text"
                                        href={`mailto:${unit.mail}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {unit.mail}
                                    </a>
                                </div>
                            )}
                            <div>
                                <a
                                    className="text"
                                    href={`mailto:${unit.mail2}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {unit.mail2}
                                </a>
                            </div>
                        </div>
                        <br />
                        <button
                            className="copy-button"
                            onClick={() => {
                                navigator.clipboard.writeText(unit.mail);
                                alert(t('Email copied to clipboard!'));
                            }}
                        >
                            {t("Copy E-mail Address")}
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ContactUsCard;
