import React, { useEffect } from 'react';
import CenterStage from '../Components/CenterStage/CenterStage';
import imgCareerStage from '../assets/Careers/career_stage_1.jpg';
import Promoter from '../Components/Promoter/Promoter';
import { Col, Row } from 'react-bootstrap';
import { MdEmail, MdLocationOn } from 'react-icons/md';
import imgWork from '../assets/Careers/work.png';
import TextCaption from '../Components/TextCaption/TextCaption';
import { useTranslation } from 'react-i18next';

function Careers() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  const isMobile = window.innerWidth <= 768;
  // const job_01 = {
  //   image: imgjob_01,
  //   title: 'We are hiring: Drongen',
  //   description: 'Warehouse Operator Toonbank',
  //   listItems: ["Do you have knowledge of hydraulics?", "Do you get energy from helping people and social contact?", "Do you like to roll up your sleeves?", "Go for it! Read our vacancy, apply and maybe see you soon!"]
  // }

  // const job_02 = {
  //   image: imgjob_02,
  //   title: 'We are hiring: Wallonie',
  //   description: 'Key Account Manager',
  //   listItems: ["Are you an enthusiastic commercial expert?", "Do you have a passion for technology?", "Do you get energy from bigger customers? ", "Go for it! Read our vacancy, apply and maybe see you soon!"]
  // }

  // const job_03 = {
  //   image: imgjob_03,
  //   title: 'We are hiring: Hasselt/Genk',
  //   description: 'Technical Commercial Officer',
  //   listItems: ["Are you customer orientated and administratively strong?", "Do you have strong technical skills?", "Do you have a knack for helping customers at the counter? ", "Go for it! Read our vacancy, apply and maybe see you soon!"]
  // }


  // const job_04 = {
  //   image: imgjob_04,
  //   title: 'We are hiring: Genk',
  //   description: 'Technical Commercial Officer',
  //   listItems: ["Do you have knowledge of hydraulics?", "Do you get energy from helping people and social contact?", "Do you like to roll up your sleeves? ", "Go for it! Read our vacancy, apply and maybe see you soon!"]
  // }

  return (
    <div>
      <CenterStage title={"Welcome to the career portal of HANSA-FLEX Thailand!"}
        imageSrc={imgCareerStage}
        paragraph={"Become part of the family and apply to our open job postings or on your own initiative. We look forward to getting to know you."}
      />

      <TextCaption
        title={"Why work at HANSA-FLEX Thailand?"}
        paragraph={[
          "If you are looking for a work environment where innovation, teamwork and personal growth are keys, don't scroll longer. At HANSA-FLEX THAILAND, you will have the opportunity to work for an international player in the hydraulics sector, where no day is the same and your commitment makes all the difference! As an employer, we support our employees in a variety of ways:"]}
        bulletPoints={["Open feedback culture and collegial cooperation.",
          "As a family-owned company, we encourage open communication and teamwork.",
          "Through that open communication, we create a flexible work-life balance."]}
      />
      <br />
      <TextCaption
        paragraph={"Become part of the family and apply to our open job postings or on your own initiative. We look forward to getting to know you."}
      />

      <br /><br />
      {/* <div>
        <br />
        <ImageText data={job_01} left={false} /> <br /><br />
      </div> */}

      {/* <div >
        <br />
        <ImageText data={job_02} /> <br /><br />
      </div>

      <div >
        <br />
        <ImageText data={job_03} left={false} /> <br /><br />

        <div >
          <br />
          <ImageText data={job_04} /> <br /><br />
        </div>
      </div> */}

      <TextCaption
        title={""}
        paragraph={t("We still look forward to meeting you and getting to know you and your skills. Simply send us an application on thn@hansa-flex.com and introduce yourself to us.")}
      />

      {isMobile ?
        <>
          <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
            <Row>
              <Col xs={12} md={6}>
                <h2>{t("Apply Now!")}</h2>
                <p>
                  <MdLocationOn /> {t("15/288 Moo11, Soi Nuanchan21, Klongkhum, Buengkum Bangkok 10230")}<br />
                </p>
                <p>
                  <MdEmail /> {t("Email us at")} <a href="mailto:jobs@hansa-flex.be" style={{ color: 'white' }}>thn@hansa-flex.com</a> now!
                </p>
              </Col>
            </Row>
          </div>
        </> :
        <Promoter
          title={"Contact us now !"}
          checklistItems={[
            "Whether you are an experienced professional or at the beginning of your career, we welcome people with a passion for technology and a driven attitude.",
            // <>Are you convinced? Great, we'd love to hear from you! Email us at <a href="mailto:jobs@hansa-flex.be" onClick={(e) => { e.preventDefault(); window.location.href = 'mailto:jobs@hansa-flex.be'; }}>thn@hansa-flex.com</a>.</>,
            "Are you convinced? Great, we'd love to hear from you! Email us at thn@hansa-flex.com.",
            "Come work at HANSA-FLEX Thailand and be part of our success story!"
          ]}
          imageUrl={imgWork}
        />}

    </div >
  );
}

export default Careers;