import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import ImageText from '../../Components/ImageText/ImageText'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../Components/ImageCard/ImageCard'

import imgStage from '../../assets/Products/Approvals/Stage.jpg'
import CertifiedReliability from '../../assets/Products/Approvals/CertifiedReliability.jpg'
import HoseLines from '../../assets/Products/Approvals/HoseLines.jpg'
import Precision from '../../assets/Products/Approvals/Precision.jpg'



const Approvals = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        listItems: ["American Bureau of Shipping (ABS)", "Federal Office of Bundeswehr Equipment, Information Technology and In-Service Support (BAAINBw)", "China Classification Society (CCS)", "Class NK", "Deutsche Bahn (DB)", "DNV Det Norske Veritas (DNV)", "DVGW – German Technical and Scientific Association for Gas and Water (DVGW)", "Lloyd’s Register (LR)", "Marine Equipment Directive (MED)", "RINA", "RINA Military", "Russian Maritime Register of Shipping (RMRS)", "Specialist Company in Accordance with the German Water Resources Act (WHG)"]
    }

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"NATIONAL AND INTERNATIONAL APPROVALS"}
                    paragraph={"HANSA-FLEX has a large number of national and international approvals and supplier qualifications. Authorities, government clients, industry associations and renowned classification societies regularly confirm and document the high quality and suitability of our offerings as a manufacturer, supplier and service provider in the course of various audits and approval procedures."}
                />
            </div>
            <div>
                <SectionHeader
                    title={"TESTED AND APPROVED"}
                    subtitle={"technik@hansa-flex.com"}
                />
                <ImageText data={data1} left={false} />
            </div>

            <div>
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={4} ><ImageCard image={CertifiedReliability}
                            title="Certified reliability and quality"
                            desc={"HANSA-FLEX AG has ISO-certified management systems for quality management, environmental management and occupational safety."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={HoseLines}
                            title="Hose lines for every purpose"
                            desc={"HANSA-FLEX offers high-quality hydraulic hose lines for all types of applications, from individual production to series production for original equipment."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={Precision}
                            title="Precision and variety: Pipelines from HANSA-FLEX"
                            desc={"HANSA-FLEX manufactures bent pipelines from various materials as individual items or in series for all common types of connections."} /></Col>
                    </Row>
                </Container>
            </div>

        </div>
    )
}

export default Approvals