import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './HfTabs.css';
import ImageText from "../ImageText/ImageText";
import { useTranslation } from 'react-i18next';

const HFTab = ({ imageTextData, tabTitles }) => {
  const { t } = useTranslation();
  
  return (
    <div className="text-center">
      <Tabs
        defaultActiveKey={tabTitles[0].eventKey}
        id="uncontrolled-tab-example"
        className="d-inline-flex flex-wrap justify-content-center"
      >
        {tabTitles.map((tab, index) => (
          <Tab key={index} eventKey={tab.eventKey} title={t(tab.title)} className="">
            <div className={`${tab.eventKey}-div`}>
              <ImageText data={imageTextData[index]} />
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default HFTab;
