import React, { useEffect } from 'react'
import imgProduct_Pipelines_Centerstage from '../../../assets/Products/Pipelines/Pipelines/Product_Pipelines_Centerstage.jpg';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgPipeline_imagecard1 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard1.jpg'
import imgPipeline_imagecard2 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard2.jpg'
import imgPipeline_imagecard3 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard3.jpg'
import imagePipelineimagecard4 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard4.jpg'
import imagePipelineimagecard5 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard5.jpg'
import imagePipelineimagecard6 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard6.jpg'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import { Container, Row, Col } from 'react-bootstrap';
import imgPipelineImagetext1 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_imagetext_1.jpg'
import imgPipelineImagetext2 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_imagetext_2.jpg'
import imgPipelineImagetext3 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_imagetext_3.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import imgMark from '../../../assets/mark.png'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTab from '../../../Components/HfTabs/HfTabs';
import fasteningTech from '../../../assets/Products/Pipelines/Pipelines/fasteningTech.jpg'
import HosePipeComb from '../../../assets/Products/Pipelines/Pipelines/HosePipeComb.jpg'
import SeriesProd from '../../../assets/Products/Pipelines/Pipelines/SeriesProd.jpg'
import ComponentGroups from '../../../assets/Products/Pipelines/Pipelines/ComponentGroups.jpg'
import AtHome from '../../../assets/Products/Pipelines/Pipelines/AtHome.jpg'


const Pipelines = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    // features card carousel data
    const featuresData = [
        {
            title: "Highest quality standards",
            description: "Pipeline components to meet the customer’s requirements, manufactured precisely and in accordance with the standards.",
            image: imgMark
        },
        {
            title: "Leading technologies",
            description: "HANSA-FLEX manufactures products using high-end machines and develops its own innovative solutions.",
            image: imgMark
        },
        {
            title: "System solutions all from the same source",
            description: "One contact partner for everything and all components are designed to work together perfectly.",
            image: imgMark
        },
        {
            title: "Decades of experience",
            description: "Advice and manufacture by experienced experts with know-how extending across many industries.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    const imgPipeline_imagetext1 = {
        image: imgPipelineImagetext1,
        title: 'Contactless geometry capture and direct data transfer',
        description: 'One of the pieces of equipment HANSA-FLEX uses for design and checking is a measuring arm. Contactless scanning captures geometries using laser or infrared technology. The 3D models created from the results are compared with the customer’s 3D data and used for additional design. Direct communication with commonly available CAD software allows data to be quickly processed. In-house software solutions allow feasibility checks to be quickly performed. The data can also be directly transferred to CAD applications via interfaces.',
    }

    const imgPipeline_imagetext2 = {
        image: imgPipelineImagetext2,
        title: 'Always state-of-the-art',
        description: ['High performance in tight installation spaces, made-to-measure one-off items, rapid delivery times and complete traceability are increasingly expected of pipelines and their providers. HANSA-FLEX spares no effort in enabling equipment suppliers to meet the increasing demands of their markets.For example, we are continually adding further machines to our production facilities, improving data networking and extending our warehouses and manufacturing centres to guarantee maximum flexibility and availability..', 'We question things critically and respect the opinions of others..', 'We are aware of our responsibilities towards colleagues and partners and implement these responsibilities.'],
    }


    const imgPipeline_imagetext3 = {
        image: imgPipelineImagetext3,
        title: 'Technical support',
        description: ['HANSA-FLEX is always available to provide technical advice and support in the design of pipelines. The company’s years of experience and expert knowledge across many industries benefit customers greatly during the initial sample design, prototype building or when planning new projects.', 'We question things critically and respect the opinions of others..', 'We are aware of our responsibilities towards colleagues and partners and implement these responsibilities.'],
    }

    const imgPipeline_imagetext4 = {
        image: AtHome,
        listItems: ["Wind energy", "Electronics", "Railway engineering", "Construction plant", "Agricultural technology", "Stationary hydraulics"],
    }

    //HfTab data
    const imageTextData = [
        {
            title: "Pipe clamps for quick and easy line assembly",
            description: "A large selection of products for securing pipe and hose lines during installation and for permanent attachment is available in the HANSA-FLEX online shop. Light and heavy series pipe clamps Modular SRS clamps from HANSA-FLEX Steel pipe clamps and double pipe clamps.",
            buttonName: "LEARN MORE",
            image: fasteningTech
        },
        {
            title: "Preassembly of combined hose-pipelines",
            description: "In addition to pipe and hose lines, HANSA-FLEX also manufactures combined hose-lines for a wide range of applications in fluid technology. Customers can also obtain comprehensive advice on these products from experienced specialists. Moreover, our HANSA-FLEX experts are a source of valuable tips on correct design and professional installation. The range of services covers:",
            listItems: ["Installation of flexible line geometries", "Advice for planning of hydraulic systems and machines", "High-quality processing using certified welding procedures"],
            buttonName: "LEARN MORE",
            image: HosePipeComb
        },
        {
            title: "Highest quality all along the line",
            description: "Series production at HANSA-FLEX is the embodiment of the highest precision, experienced operatives, reliable quality testing and a perfect all-round service from production to dispatch. The customer-specific preassembled pipe and hose lines are manufactured in a high process reliability production environment. HANSA-FLEX’s state-of-the-art production methods ensures rapid availability and maximum compliance with delivery schedules.",
            buttonName: "LEARN MORE",
            image: SeriesProd
        },
        {
            title: "Customised component groups",
            description: "HANSA-FLEX preassembles pipes into component groups to meet the customer’s specific requirements and delivers them ready for installation. The component groups can be, for example, diverse combinations of straight, bent or branched pipes or involve the preinstallation of connections, expansion joints or hose lines. This saves the customer assembly time and storage space for the individual components. The experts at the HANSA-FLEX branch dealing with the project are always available for specialist advice.",
            buttonName: "CONTACT US",
            image: ComponentGroups
        },
    ];

    const tabTitles = [
        { title: "Fastening technology", eventKey: "Fastening technology" },
        { title: "Hose-pipe combination", eventKey: "Hose-pipe combination" },
        { title: "Series production", eventKey: "Series production" },
        { title: "Component groups", eventKey: "Component groups" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgProduct_Pipelines_Centerstage}
                    title={"HANSA-FLEX PIPELINES FOR LIQUID AND GASEOUS MEDIA"}
                    paragraph={"The HANSA-FLEX range of pipeline products includes expansion joints, bent pipes and special fittings for liquids, gases and coolant water. HANSA-FLEX’s state-of-the-art manufacturing technologies ensure absolute precision, the highest quality and rapid production to meet the customer’s specifications exactly. HANSA-FLEX sets new standards in connection technology with its own innovative pipe-forming system HF-Form, which is soon to become available."}

                />
            </div>

            <div>
                <SectionHeader
                    title={"YOUR SPECIALIST FOR EVERYTHING TO DO WITH PIPELINES"}
                    subtitle={"HANSA-FLEX offers customers all they need to equip their machines and systems with safe, high-quality pipelines."}
                />
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                            <ImageCard image={imgPipeline_imagecard1} learnMoreLink="/hydraulic-hoses" title="Bent pipes precisely manufactured to the customer’s requirements" desc="HANSA-FLEX has decades of experience in bending precision pipes made from steel, stainless steel, copper and other materials. Lengths of up to 6,000 mm and diameters..." />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                            <ImageCard image={imgPipeline_imagecard2} learnMoreLink="/metal-hoses" title="Customised pipe-end processing and special fittings" desc="HANSA-FLEX offers customised connection options for every pipe connection. All types of forming processes, such as swaging or rolling, in addition to cutting ring, threaded..." />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                            <ImageCard image={imgPipeline_imagecard3} learnMoreLink="/Industrial-hoses" title="Protect systems with HANSA-FLEX expansion joints" desc="HANSA-FLEX expansion joints in silicone, PTFE, NBR or stainless steel protect machines and equipment against damage from shocks, expansion and vibration..." />
                        </Col>

                    </Row>
                </Container>
            </div>

            <div>
                <SectionHeader
                    title={"GOOD REASONS FOR USING PIPELINES FROM HANSA-FLEX"}
                    subtitle={"These points set apart HANSA-FLEX from the rest as a specialist in high quality pipelines."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"THE LATEST TECHNOLOGIES AND SOUND SPECIALIST KNOWLEDGE"}
                />
                <ImageText data={imgPipeline_imagetext1} />

                <ImageText data={imgPipeline_imagetext2} left={false} />

                <ImageText data={imgPipeline_imagetext3} />
            </div>

            <div>
                <SectionHeader
                    title={"FURTHER PIPELINE PRODUCTS AND SERVICES"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"AT HOME IN MANY INDUSTRIES"}
                    subtitle={"HANSA-FLEX has comprehensive knowledge of many areas of industry, which we use to secure real competitive advantages for our customers."}
                />
                <ImageText data={imgPipeline_imagetext4} />
            </div>

            <div>
                <SectionHeader
                    title={"MORE PRODUCTS FROM HANSA-FLEX"}
                />
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                            <ImageCard image={imagePipelineimagecard4} learnMoreLink="/hydraulic-hoses" title="Safely connect hydraulic components with pipe fittings" desc="Pipe fittings rely on a tight positive fit to create a safe connection, even when subjected to high working pressures, pressure peaks and vibrations." />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                            <ImageCard image={imagePipelineimagecard5} learnMoreLink="/metal-hoses" title="Flanges for high-pressure applications" desc="HANSA-FLEX flange fittings are ideal for large diameters, high-pressure applications and restricted installation situations." />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                            <ImageCard image={imagePipelineimagecard6} learnMoreLink="/Industrial-hoses" title="High-quality hoses for numerous fields of use" desc="The perfect choice for moving connections in fluid technology or for the transport of chemicals, foodstuff, air, water and abrasive media." />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Pipelines
