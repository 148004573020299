import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../Components/ImageCard/ImageCard';
import ImageText from '../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../assets/mark.png';

import imgHydraulicSeminars from '../../assets/Services/HydraulicAcademy/HydraulicAcademy.jpg'
import imgInHouseSeminars from '../../assets/Services/HydraulicAcademy/InHouseSeminars.jpg'
import imgRightFromStart from '../../assets/Services/HydraulicAcademy/RightFromStart.jpg'
import imgRulesOfOilCare from '../../assets/Services/HydraulicAcademy/RulesOfOilCare.jpg'
import imgTheoryToPractical from '../../assets/Services/HydraulicAcademy/TheoryToPractical.jpg'
import imgTrainingFromFirst from '../../assets/Services/HydraulicAcademy/TrainingFromFirst.jpg'
import imgTrainingOnLatestTech from '../../assets/Services/HydraulicAcademy/TrainingOnLatestTech.jpg'

//TODO: TextPoster
//TODO: TextPoster
//TODO: Client review

const HydraulicAccademy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //ImageText Data
    const TrainingFromFirst = {
        image: imgTrainingFromFirst,
        title: 'Training from first principles to refresher courses',
        description: 'The range of courses and seminars from HANSA-FLEX and the IHA is intended for people whose everyday work involves hydraulics. The training in hydraulics offered extends from foundation level to much deeper special knowledge on individual topics such as project planning or system maintenance. Practice-orientated training provides an opportunity to try out the theory learned on earlier courses. A key qualification for people working in the field of hydraulics is the training to become an officially authorised person in accordance with BetrSichV and TRBS 1203-2019. For experienced employees, there are refresher courses to bring their knowledge up to the current state of the technology with respect to engineering, application and safety. All training is delivered by certified experts.',
    }

    const TrainingOnLatestTech = {
        image: imgTrainingOnLatestTech,
        title: 'Training in and use of the latest technology',
        description: 'The IHA has modern and fully equipped seminar rooms at all its locations and hydraulic training benches with the latest technology in Linz, Stockstadt and Dresden. Here participants enjoy learning about everyday tasks on hydraulic systems and how to design them so that they are more efficient, safer and better fulfil their intended purpose. Special mention should be made of the training available at Coreum in Stockstadt am Rhein. At the Coreum centre, which has several small construction sites set up specifically for training, HANSA-FLEX and the IHA are joined by machine manufacturers. Participants can apply their acquired hydraulics knowledge directly in practice under real conditions, which helps to consolidate what they have learned.'
    }

    const InHouseSeminars = {
        image: imgInHouseSeminars,
        title: 'In-house seminars at the IHA or at the customer’s premises',
        description: "In addition to training at various locations in Germany and abroad, the IHA can also provide in-house training. This training can take place at a nearby branch of HANSA-FLEX or at the customer’s premises. If you are interested in training for your staff or have any questions about training, please do not hesitate to contact us. We are always happy to assist you.",
        buttonName: "Contact Us",
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Gain market recognition for your expertise",
            description: "Properly trained and qualified personnel impress customers.",
            image: imgMark
        },
        {
            title: "Increased cost-efficiency",
            description: "Greater knowledge and skills reduce the number of mistakes, while increasing productivity and process efficiency.",
            image: imgMark
        },
        {
            title: "Improve operational safety",
            description: "Participants learn about the legal provisions governing personal and corporate liability.",
            image: imgMark
        },
        {
            title: "Certificate on completion of training",
            description: "Successfully completed training is certified by the IHA and nationally recognised.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ]; 

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHydraulicSeminars}
                    title={"TRAINING IN FLUID AND LINE TECHNOLOGY"}
                    paragraph={"Education is the best means of combating the shortage of skilled personnel HANSA-FLEX uses active knowledge transfer from experienced specialists to apprentices, employees and customers. HANSA-FLEX offers training and education in the field of fluid and line technology as well as training programmes for hydraulic specialists, which it delivers together with its partner the International Hydraulics Academy (IHA)."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'EXPERT KNOWLEDGE OF THEORY AND PRACTICE'}
                />
                <ImageText
                    data={TrainingFromFirst} />
                <ImageText
                    data={TrainingOnLatestTech} left={false} />
                <ImageText
                    data={InHouseSeminars} />
            </div>

            <div>
                <SectionHeader
                    title={"QUALIFICATIONS BUILD THE FOUNDATION FOR SUCCESS"}
                    subtitle={"The courses and seminars provided jointly by HANSA-FLEX and the IHA transfer top-class specialist knowledge from which hydraulics companies and their customers benefit in terms of safety and quality."}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO:Red carousel */}

            <div>
                <SectionHeader
                    title={"YOU MIGHT ALSO BE INTERESTED IN"}
                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgTheoryToPractical}
                                title="From theory to practical applications"
                                desc={"The Coreum training center offers construction sites and training benches where course participants can experience the power and functions..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgRulesOfOilCare}
                                title="The 10 golden rules of oil care"
                                desc={"Find out what to look out for in fluid management and how HANSA-FLEX supports you with comprehensive services so that everything runs smoothly."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgRightFromStart}
                                title="Professional and consistent from the start"
                                desc={"Our task is to ensure your success. HANSA-FLEX is your experienced partner, always at your side, assisting and advising you from project conception to the"} /></Col>
                        </Row>

                    </Container>
                </div>

            </div>

        </div>
    )

}

export default HydraulicAccademy