import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgProduction_Centerspread from '../../../assets/Services/Production/Production/Production_Centerspread.jpg';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../Components/ImageText/ImageText';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook;
import imgProduction_Imagetext_01 from '../../../assets/Services/Production/Production/Production_Imagetext_01.jpg';
import imgProduction_Imagetext_02 from '../../../assets/Services/Production/Production/Production_Imagetext_02.jpg';
import imgProduction_Imagetext_03 from '../../../assets/Services/Production/Production/Production_Imagetext_03.jpg';
import imgProduction_Imagetext_04 from '../../../assets/Services/Production/Production/Production_Imagetext_04.jpg';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgProduction_Imagecard_01 from '../../../assets/Services/Production/Production/Production_Imagecard_01.jpg';
import imgProduction_Imagecard_02 from '../../../assets/Services/Production/Production/Production_Imagecard_02.jpg';
import imgProduction_Imagecard_03 from '../../../assets/Services/Production/Production/Production_Imagecard_03.jpg';
import imgProduction_Imagecard_04 from '../../../assets/Services/Production/Production/Production_Imagecard_04.jpg';
import imgProduction_Imagecard_05 from '../../../assets/Services/Production/Production/Production_Imagecard_05.jpg';
import imgProduction_Imagecard_06 from '../../../assets/Services/Production/Production/Production_Imagecard_06.jpg';
import imgProduction_Imagecard_07 from '../../../assets/Services/Production/Production/Production_Imagecard_07.jpg';
import imgProduction_Imagecard_08 from '../../../assets/Services/Production/Production/Production_Imagecard_08.jpg';
import imgProduction_Imagecard_09 from '../../../assets/Services/Production/Production/Production_Imagecard_09.jpg';
import imgProduction_Imagecard_10 from '../../../assets/Services/Production/Production/Production_Imagecard_10.jpg';
import imgProduction_Imagecard_11 from '../../../assets/Services/Production/Production/Production_Imagecard_11.jpg';
import imgProduction_Imagecard_12 from '../../../assets/Services/Production/Production/Production_Imagecard_12.jpg';
import { Container, Row, Col } from 'react-bootstrap';



const Production = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Production_Imagetext_01 = {
    image: imgProduction_Imagetext_01,
    title: 'Your partner for any project',
    description: ['HANSA-FLEX production facilities can confidently fulfil a wide range of customer requirements. We manufacture prototypes individually to the customer’s specifications and implement special projects or remanufacture components, as one-off items or in small or large batch series production. Because our in-house know-how and skills cover the whole spectrum of hydraulics, we manufacture and preassemble customised combinations of hoses, pipes, expansion joints, cylinders and special fittings, often combining them into complete component groups. The highest process reliability and state-of-the-art machines allow us to supply industrial customers as an OEM supplier with large batch series production of several tens of thousands of items per month.'],
  }

  const Production_Imagetext_02 = {
    image: imgProduction_Imagetext_02,
    title: 'Versatile skills and great specialist knowledge',
    description: 'We are completely familiar with a wide range of manufacturing techniques at our production locations. In addition, we have qualified and certified specialist personnel and state-of-the-art machines.',
    listItems: ['Soldering furnaces for sophisticated soldering and brazing procedures', 'Certified orbital and stainless steel welders, In-house paint shop, 5-axis machining centre for accurately machined parts', 'Precise CNC bending machines, bending and welding robots', 'Quality assurance at our own test benches'],
  }

  const Production_Imagetext_03 = {
    image: imgProduction_Imagetext_03,
    title: 'Advice and assistance throughout the complete manufacturing process.',
    description: ['Our production operations are not limited to manufacturing components. As a system partner, we are there to help and assist our customers during the whole manufacturing process. We design and develop products to the customer’s specific requirements and optimise them until everything is right. After manufacture, we look after quality assurance and bringing into first use. Not only for small batches of components but also for series production at an industrial scale. On the logistics side, we support our customers with ready-to-install kits, Just-In-Time or Just-In-Sequence delivery and the kanban system.'],
  }

  const Production_Imagetext_04 = {
    image: imgProduction_Imagetext_04,
    description: 'HANSA-FLEX has production facilities in several countries. They coordinate closely with one another and work to the same standards. This balanced, decentralised and global structure has numerous advantages:',
    listItems: ['Reduced failure risk and high security of supply', 'Cost reduction through the use of synergies', 'The same high quality standards all over the world'],
  }


  const featuresData = [
    {
      title: "Competence in all areas",
      description: "Comprehensive know-how, modern machines, prototype manufacture and large batch series production.",
      image: imgMark
    },
    {
      title: "Flexibility to meet customer requirements",
      description: "With design, production and logistics all in house, we leave no customer requirement unfulfilled.",
      image: imgMark
    },
    {
      title: "Reliable and rapid implementation",
      description: "Always on schedule. Urgent orders can often be delivered next day.",
      image: imgMark
    },
    {
      title: "Proven quality",
      description: "Assured process reliability and achievement of the proven HANSA-FLEX quality.",
      image: imgMark
    },

    // Add more objects for additional FeaturesCard data
  ];

  return (
    <div>

      <CenterStage imageSrc={imgProduction_Centerspread}
        title={"MAXIMUM FLEXIBILITY WITH IN-HOUSE PRODUCTION"}
        paragraph={"HANSA-FLEX is not only Europe’s largest hydraulics dealer, it is also a leading manufacturer of high-quality components such as power units, metal and special hoses, special fittings, valve blocks, seals and cylinders. In our production facilities we can manufacture anything from one-off items to large series production components as an OEM. Our 450 employees and state-of-the-art machines mean we can achieve maximum flexibility and the highest quality."}
      />

      <div>
        <SectionHeader
          title={"HYDRAULIC SYSTEM PARTNER WITH IN-HOUSE PRODUCTION"}
          subtitle={""}
        />
        <div >
          <br />
          <ImageText data={Production_Imagetext_01} left={false} />
        </div>

        <div >
          <br />
          <ImageText data={Production_Imagetext_02} />
        </div>

        <div >
          <br />
          <ImageText data={Production_Imagetext_03} left={false} />
        </div>
      </div>


      <SectionHeader
        title={"THE COMPLETE MANUFACTURING PROCESS FROM A SINGLE SUPPLIER"}
        subtitle={"HANSA-FLEX is a hydraulic system partner and works flexibly in accordance with the customer’s wishes, delivering products and services to the same consistently high quality."}
      />

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <div>
        <SectionHeader
          title={"HANSA-FLEX PRODUCTION FACILITIES"}
          subtitle={"HANSA-FLEX has extensive production skills in-house. We can react quickly and flexibly."}
        />
      </div>

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProduction_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Series production on an industrial scale" desc="We provide companies as an OEM supplier with ready-to-install pipelines and hose lines, preassembled component groups and kits in small or large batch series production." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgProduction_Imagecard_02} learnMoreLink="/metal-hoses" title="Prototype manufacturing" desc="HANSA‑FLEX manufactures individual items to suit customer specifications, drawings or samples, and if required advises and assists with the product on its way to series." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProduction_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Customised unit manufacture" desc="HANSA‑FLEX develops and manufactures efficient hydraulic power units to the customer’s requirements and offers complete systems including hydraulic." />
          </Col>

          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProduction_Imagecard_04} learnMoreLink="/hydraulic-hoses" title="Special fittings manufactured for special requirements" desc="HANSA-FLEX designs and produces fittings to perfectly match the hose. We manufacture special fittings in addition to our standard connection components." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgProduction_Imagecard_05} learnMoreLink="/metal-hoses" title="Sealing technology made-to-measure" desc="Hydraulic and pneumatic systems are only as good as their seals. If special shapes are required, HANSA-FLEX manufactures them as one-off items or in small batch series." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProduction_Imagecard_06} learnMoreLink="/Industrial-hoses" title="Metal hoses for demanding conditions" desc="We manufacture metal hoses to resist high mechanical loads and vacuums. They can also be used in combination with PTFE hoses for a wide range of different media." />
          </Col>

       
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProduction_Imagecard_07} learnMoreLink="/hydraulic-hoses" title="Industrial hoses for any number of applications" desc="Industrial hoses transport a wide range of media from water and air through chemical substances right up to fuels , gases and foodstuff." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgProduction_Imagecard_08} learnMoreLink="/metal-hoses" title="Precision bent pipes" desc="We produce expansion joints, pipe bends and special fittings using state-of-the-art manufacturing technology to the highest precision and in first class quality." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProduction_Imagecard_09} learnMoreLink="/Industrial-hoses" title="Servicing and manufacturing hydraulic cylinders" desc="HANSA-FLEX overhauls defective hydraulic cylinders made by any manufacturer and manufactures suitable replacements from a sample, drawing or customer requirements." />
          </Col>

        </Row>
      </Container>


      <SectionHeader
        title={"INTERNATIONAL PRODUCTION FACILITIES"}
        subtitle={"The global and decentralised organisation of our production facilities ensures security of supply and consistent quality."}
      />
      <div >
        <br />
        <ImageText data={Production_Imagetext_04} left={false} />
      </div>


      <SectionHeader
        title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
        subtitle={""}
      />


      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProduction_Imagecard_10} learnMoreLink="/hydraulic-hoses" title="The mobile emergency service for urgent action" desc="With around 200 service vehicles across Germany, our mobile Rapid Hydraulic Service is always there for you, available 24/7 and 365 days a year to minimise your stoppage" />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgProduction_Imagecard_11} learnMoreLink="/metal-hoses" title="Assembly and installation by experienced technicians" desc="HANSA-FLEX highly trained service technicians perform the assembly and installation on your hydraulic systems and machines." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProduction_Imagecard_12} learnMoreLink="/Industrial-hoses" title="Work equipment and operating materials reliably inspected" desc="HANSA-FLEX supports you with inspection of hydraulic systems and their components in accordance with legally prescribed requirements such as the provisions of..." />
          </Col>

        </Row>
      </Container>
    </div>
  )
}
export default Production;