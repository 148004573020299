import React, { useEffect } from 'react';
import HFTab from '../../Components/HfTabs/HfTabs';
import CenterStage from '../../Components/CenterStage/CenterStage';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../Components/ImageCard/ImageCard';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../assets/mark.png';

import imgMobileService from '../../assets/Services/MobileService/MobileService.jpg'
import img360Hose from '../../assets/Services/MobileService/360HoseManagement.jpg'
import imgHFNeverFar from '../../assets/Services/MobileService/HFNeverFar.jpg'
import imgOilCareEasy from '../../assets/Services/MobileService/OilCareEasy.jpg'
import imgDispatch from '../../assets/Services/MobileService/Dispatch.jpg'
import imgDescription from '../../assets/Services/MobileService/Description.jpg'
import imgSpareParts from '../../assets/Services/MobileService/SpareParts.jpg'
import imgDocumentation from '../../assets/Services/MobileService/Documentation.jpg'
import imgHoseManufacturing from '../../assets/Services/MobileService/HoseManufacturing.jpg'

//TODO: Carousal
//TODO: video
//TODO: Textposter

const MobileRapidHydraulicService = () => {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    // features card carousel data
    const featuresData = [
        {
            title: "By telephone",
            description: "You can call the emergency service using the toll-free service hotline at 0800 - 77 12345.",
            image: imgMark
        },
        {
            title: "Contact us by mobile app",
            description: "You can contact the emergency service free-of-charge using the HANSA-FLEX app.",
            image: imgMark
        },
        {
            title: "Online form",
            description: "You can request support from the mobile emergency service directly using our online form.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Digital dispatch",
            description: "Our experience dispatchers are available all around the clock for you. Using highly capable software, they always have a full overview of all orders, the intended route of every vehicle and its present location. One click of a mouse reveals the location of the mobile service vehicle that can reach you the quickest. The dispatchers send the service technician all the important information about the order, which appears on the tablet in the vehicle before it arrives on site, so that the work can be completed efficiently. In this way, the mobile emergency service technician is fully prepared before the vehicle arrives at your premises.",
            image: imgDispatch
        },
        {
            title: "Precise description",
            description: "We need your support to ensure that the processes can be handled as quickly as possible. Please ensure that the information you give us about the requested service is as precise as possible:",
            listItems: ["What are the names of the contacts?", "Which parts or components are defective?", "Are there any photographs of the installation situation?", "What replacement parts are probably needed?"],
            image: imgDescription
        },
        {
            title: "Fitted out perfectly",
            description: "The mobile service vehicles carry a stock of the spare and wear parts most frequently needed for the relevant field of use. The software used in our mobile Rapid Hydraulic Service connects directly with our Materials Management team. The dispatcher can therefore already see which spare parts are carried on board every vehicle when the servicing order is placed. If other parts are required for the work on site, the mobile service vehicle can make a planned intermediate stop en route at the nearest HANSA-FLEX branch.",
            image: imgSpareParts
        },
        {
            title: "Mobile hose manufacture",
            description: "The service vehicles in the mobile Rapid Hydraulic Service fleet are workshops on wheels. They carry all the equipment necessary for manufacturing customised hydraulic hose lines, including hose cutting, peeling and crimping machines. The underfloor generator provides a reliable, independent power supply on construction sites or in remote areas and allows the technicians to work at night. The 400 volt generator makes the workshop on wheels completely independent of the circumstances on site.",
            image: imgHoseManufacturing
        },
        {
            title: "Comprehensive documentation",
            description: "During the work, the service technicians take high-resolution photos of the damage and the newly installed components with their tablets and file all the information in the service report. This can be countersigned directly on site by the customer on the tablet, who will receive the report together with the invoice by e-mail. If the customer uses X-CODE and the hose management portal My.HANSA-FLEX, the service technician can store the details of the hose line replacement directly in the system.",
            image: imgDocumentation
        },
    ];

    const tabTitles = [
        { title: "Dispatch", eventKey: "Dispatch" },
        { title: "Description", eventKey: "Description" },
        { title: "Spare parts", eventKey: "Spare parts" },
        { title: "Hose manufacture", eventKey: "Hose manufacture" },
        { title: "Documentation", eventKey: "Documentation" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgMobileService}
                    title={"READY FOR ACTION AT ANY TIME: THE RAPID HYDRAULIC SERVICE"}
                    paragraph={"When hydraulic components fail unexpectedly, you have to act fast. With 180 vehicles across Germany, our mobile Rapid Hydraulic Service is always there for you, available 24/7 and 365 days a year to minimise your stoppage times. Our well-trained and experienced service technicians are with you within an hour. They rectify defects, install replacement parts and manufacture customised hose lines on site in their on-board workshop."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SENDING FOR THE MOBILE EMERGENCY SERVICE IS QUICK AND EASY"}
                    subtitle={"The mobile emergency service is there for you when things go wrong. Contact us and our customer service centre takes care of all the planning and coordinates with the service technicians."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"EFFICIENT HANDLING FROM DISPATCH TO DOCUMENTATION"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            {/* TODO here */}

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={img360Hose}
                                title="360° hose management"
                                desc={"The required parts can be identified quickly and reliably using the HANSA-FLEX X-CODE. The six-digit code is permanently attached to hose lines and is easy to read."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHFNeverFar}
                                title="HANSA-FLEX is never far away"
                                desc={"We have a closely meshed global branch network. This is how we ensure that support from HANSA-FLEX experts is never far away."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgOilCareEasy}
                                title="Oil care made easy"
                                desc={"The HANSA-FLEX Fluid Service performs oil analyses in the laboratory or directly on site, carries out oil cleaning and advises you on all questions about hydraulic oil."} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>

        </div>
    )

}

export default MobileRapidHydraulicService