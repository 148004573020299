import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgMark from '../../../assets/mark.png';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import imgHydraulicHosesForEvery from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/hydraulicHosesForEvery.jpg'
import imgInThermoplastic from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/InThermoplastic.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import imgSteelOrTextile from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/steelOrTextile.jpg'
import imgTestHydraulic from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/testedHydraulic.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgOnsiteAdvice from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/onSiteAdvice.jpg'
import imgProducedInProven from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/producedInProven.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import imgSafetyEquipment from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/safetyEquipment.jpg'
import imgHighQuality from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/highQuality.jpg'
import imgSafeAndDependable from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/safeAndDependable.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import Promoter from '../../../Components/Promoter/Promoter';
import imgTabletMobile from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/tabletMobile.png'
import imgHydraulicHoseLine from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/HydraulicHoseLine.jpg'
import HFTabs from '../../../Components/HfTabs/HfTabs';
import imgSeriesProduction from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/SeriesProduction.jpg'
import imgPlantInPlantProduction from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/PlantInPlantProduction.jpg'
import imgHoseManagement from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/HoseManagement.jpg'
import imgHydraulicLineInstallation from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/HydraulicLineInstallation.jpg'
import imgHoseTesting from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/HoseTesting.jpg'






function HydraulicHose() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const InThermoplastic1 = {
        image: imgInThermoplastic,
        title: 'In thermoplastic or elastomeric materials',
        description: 'Elastomeric hydraulic hoses stand out for their good resistance to oils and aging and maintain their flexibility, even at temperatures down to -40 °C. The advantages of thermoplastic hoses include their low weight, excellent chemical resistance, and flexibility. Elastomeric and thermoplastic hoses are extremely resistant to UV radiation and have high impulse load resistance.',
    }

    const InThermoplastic2 = {
        image: imgSteelOrTextile,
        title: 'Pressure supports in steel or textiles',
        description: "Two different types of winding are used for steel reinforcement in the pressure support layer. Braided steel wires are used for braided hoses. The braiding around the hose's core layer is formed by weaving the wire alternately in two different directions.In hoses reinforced with spiral, the steel wire is wound around the inner layer.By using up to six layers, this construction form is suitable for high pressures up to 500 bar.In the case of textile- reinforced tubes, high-tech synthetic fibers such as aramid provide very good pressure stability.Furthermore, these hoses are flexible and lightweight and are used in low - pressure applications up to 100 bar.",
    }

    const InThermoplastic3 = {
        image: imgTestHydraulic,
        title: 'Hydraulic hoses tested with excellent availability',
        description: 'As part of our quality assurance system, all hydraulic hoses in the HANSA-FLEX product range are regularly tested on our own impulse and rupture pressure test benches. In addition, HANSA-FLEX is able to test hydraulic hoses and hose/equipment combinations, which are manufactured according to specific customer requirements, such as suitability for higher pressures or alternative usage scenarios. Also in terms of availability, we do not disappoint. As the largest dealer and supplier of hydraulic systems in Europe, we can rely on our two main warehouses and our branch network to ensure excellent availability.',
    }
    // ImageText On-site advice .......
    const OnsiteAdvice1 = {
        image: imgOnsiteAdvice,
        title: 'On-site advice by experienced technicians',
        description: 'Only with experience and know-how is it possible to reliably determine which hydraulic pipes are most suitable for a specific purpose. HANSA-FLEX fluid specialists offer comprehensive advice and are happy to show you your optimization options through material selection and system design. You can contact our teams at any of the HANSA-FLEX branches worldwide. Our on-site service technicians are also happy to meet with you directly on-site and advise you on the installation of your hydraulic hoses.',
    }
    const OnsiteAdvice2 = {
        image: imgProducedInProven,
        title: "Produced with HANSA-FLEX's proven quality",
        description: 'At more than 450 branches throughout Germany, HANSA-FLEX technicians manufacture pre-assembled and ready-to-install hydraulic pipes according to X-CODE data or your samples. The hoses are crimped, tested, and professionally cleaned. The highest quality standards apply to these pre-assembled hydraulic hoses. The result is a leak-proof positive connection between the hydraulic hose and the connected fitting, ensuring safety in use, even at high pressures.',
    }

    const OnsiteAdvice3 = {
        image: imgHydraulicHoseLine,
        listItems: ["In elastomeric or thermoplastic material", "Pressure supports with one or two layers of steel braid", "Pressure supports up to six layers of spiral-wound wire", "Textile pressure supports for flexibility and weight savings", "Nominal diameters from 5 to 76 mm, pressure ranges up to 500 bar", "Components designed to work perfectly together"]
    }

    // features card carousel data
    const hoseLinesAdvantagesfeaturesData = [
        {
            title: "Excellent availability",
            description: "As the largest dealer of hydraulic equipment in Europe, we can rely on the flexibility and availability of products.",
            image: imgMark
        },
        {
            title: "Internal quality assurance",
            description: "Hydraulic hoses and hose lines are tested on our own in-house test benches.",
            image: imgMark
        },
        {
            title: "Advice and service",
            description: "With us, you can be sure that the hydraulic hose line you receive is perfect for your application",
            image: imgMark
        },
        {
            title: "Pre-assembled hoses according to customer wishes",
            description: "Hydraulic hose lines are manufactured in HANSA-FLEX branches.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Series production of hose lines",
            description: "Our central series production unit for hose lines in Bremen manufactures pre-assembled and ready-to-install hydraulic, high-pressure, PTFE, and industrial hose lines, in large or small batches, for OEM suppliers. With us, you can expect excellent process reliability and compliance with quality standards. We also tailor our warehouse and logistics management procedures to your needs.",
            buttonName: "LEARN MORE",
            buttonLink: "/SeriesProduction",
            image: imgSeriesProduction
        },
        {
            title: 'Plant-in-plant production for process efficiency',
            description: "With HANSA-FLEX's Plant-in-plant production, you benefit from hydraulic hoses and other components manufactured in harmony with your internal processes.",
            listItems: ["The required components are provided sequentially", "Any unforeseen adaptations needed are quickly made on-site", "Immediate availability of components, without storage costs"],
            buttonName: "LEARN MORE",
            buttonLink: "/Worksinworks",
            image: imgPlantInPlantProduction
        },
        {
            title: "Hose management with X-CODE",
            description: "Each hydraulic hose line is assigned an individual X-CODE to uniquely identify it. Any HANSA-FLEX branch can use the X-CODE information to manufacture the respective article. In combination with the MY.HANSA-FLEX customer portal, the X-CODE is the ideal tool for our customers to manage all their hoses. Customers can see the most important information, such as the next inspection or replacement date, at a glance in an easily understandable database.",
            buttonName: "LEARN MORE",
            buttonLink: "/hose-management",
            image: imgHoseManagement
        },
        {
            title: "Installation of pipes and hoses for complete installations",
            description: "Our service technicians and installation teams install all pipes and hoses on machines, systems, or mobile hydraulic units. They start by identifying optimization potential, considering positions, dimensions, design, material choice, and efficiency. The next step is to agree on a time frame for the work and then implement the necessary measures.",
            buttonName: "LEARN MORE",
            buttonLink: "/installation-of-pipe-and-hoseline-systems",
            image: imgHydraulicLineInstallation
        },
        {
            title: "Increased safety thanks to professional hose testing",
            description: "Hydraulic hoses and connections must be regularly inspected. The safety of personnel, hydraulic systems, and machinery depends on hydraulic hoses being free from faults and defects. Tests take into account DGUV standards and the German Occupational Health and Safety Act (BetrSichV), as well as relevant product and test standards. HANSA-FLEX experts are familiar with all applicable laws and guidelines, ensuring all tests meet legal requirements.",
            buttonName: "LEARN MORE",
            buttonLink: "/dguv-inspection",
            image: imgHoseTesting
        },
    ];

    const tabTitles = [
        { title: "Series production", eventKey: "Series production" },
        { title: "Plant-in-plant production", eventKey: "Plant-in-plant production" },
        { title: "Hose management", eventKey: "Hose management" },
        { title: "Hydraulic line installation", eventKey: "Hydraulic line installation" },
        { title: "Hose testing", eventKey: "Hose testing" },
    ];

    return (
        <div>
            <CenterStage
                imageSrc={imgHydraulicHosesForEvery}
                title={"HYDRAULIC HOSES FOR EVERY APPLICATION"}
                paragraph={"Hydraulic hoses safely transfer fluid through the system. Their flexibility requires very little installation space. The HANSA-FLEX range includes elastomeric and thermoplastic hoses with pressure supports with textile or steel braid or various layers of spiral-wound steel wire. This allows them to transport fluids with pressures up to 500 bar. The hoses are used in numerous applications in mobile and stationary hydraulic systems."}
            //TODO: button (All products)
            />
            <div>
                <SectionHeader
                    title={"HIGH-QUALITY HYDRAULIC HOSES FROM HANSA-FLEX"}
                    subtitle={"All hydraulic hoses for low, medium, and high-pressure applications, and suction hoses are made from high-quality materials and undergo extensive testing."}
                />
            </div>

            <div>
                <ImageText
                    data={InThermoplastic1} />

                <ImageText
                    data={InThermoplastic2}
                    left={false} />

                <ImageText
                    data={InThermoplastic3} />
            </div>

            <div>
                <Promoter
                    title={"EASILY CONFIGURE HOSE ASSEMBLIES YOURSELF"}
                    checklistItems={["Nominal widths from 6 to 32, temperature range from -40°C to +100°C.", "Connection types: pressure nozzle, Interlock pressure fittings"]}
                    btnLabel1={"Configure now"}
                    btnLabel2={"Learn more"}
                    btnLabel1Link={"https://shop.hansa-flex.be/nl_NL/configurator"}
                    btnLabel2Link={"/hose-line-config"}
                    imageUrl={imgTabletMobile}
                />
            </div>

            <div>
                <SectionHeader
                    title={"HANSA-FLEX HOSE LINES HAVE THE FOLLOWING ADVANTAGES"}
                    subtitle={"With our own testing laboratory, two central warehouses, and an extensive branch network, HANSA-FLEX can meet all customer wishes quickly and reliably."}
                />

                <FeatureCardCarousel
                    featuresData={hoseLinesAdvantagesfeaturesData} />
            </div>

            <div>
                <ImageText
                    data={OnsiteAdvice1} />

                <ImageText
                    data={OnsiteAdvice2}
                    left={false} />

            </div>

            <div>
                <SectionHeader
                    title={"HIGH-QUALITY HYDRAULIC HOSE LINES FOR ALL APPLICATIONS"}
                    subtitle={"The HANSA-FLEX portfolio includes hydraulic hoses in numerous models and material combinations for all applications in mobile and stationary hydraulic systems."}
                />
                <ImageText
                    data={OnsiteAdvice3}
                />
            </div>

            {/*TODO red CTA*/} or {/* TODO: TextPoster */}

            <div>
                <SectionHeader
                    title={"HOSE-RELATED SERVICES"}
                    subtitle={"In addition to manufacturing hose lines in series production facilities, HANSA-FLEX also offers various solutions for manufacturing customized and pre-assembled solutions directly on-site. Additionally, our experienced fluid specialists can assist and advise you."}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"SELECTED HANSA-FLEX PRODUCTS FOR YOUR HYDRAULIC HOSES"}

                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgSafetyEquipment} learnMoreLink={"/hose-accessories"}
                                title="Safety equipment and accessories"
                                desc={"HANSA-FLEX hose lines excel in numerous applications due to their excellent quality. Products from the HANSA-FLEX accessory portfolio not only protect vulnerable hose parts in situations where particularly high mechanical loads or effects of high temperatures occur, but also protect people, for example, from injuries caused by oil jets. A wide range of products and expert advice ensure the selection and implementation of effective and efficient protection solutions."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgHighQuality} learnMoreLink={"/hose-fitting"}
                                title="High-quality hose connections"
                                desc={"Accessories designed and manufactured by HANSA-FLEX are perfect for use with the appropriate type of hose. They ensure a secure, leak-free connection. The range includes all nominal diameters and connection types commonly requested and complies with international standards (BS, SAE, DIN, and NF). The fittings are zinc-nickel coated and meet the highest durability and corrosion resistance requirements."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgSafeAndDependable} learnMoreLink={"/coupling"}
                                title="Safe and reliable hydraulic couplings"
                                desc={"Couplings are the best choice in situations where fittings need to be quickly changed, machines securely connected, or hoses heavily loaded in mobile and stationary hydraulic systems need to be replaced."} /></Col>
                        </Row>

                    </Container>
                </div>

            </div>


        </div>
    );
}

export default HydraulicHose;


