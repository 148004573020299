import React, { useEffect } from 'react';
import imgExeHigPresHose from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/ExtHighPresHose.jpg'
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgOilAndGas from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/oilAndGas.jpg'
import imgApplication from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/application.jpg'
import imgwaterJet from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/waterJet.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import imghoseProfessional from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/hoseProfessional.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgHydraulicUpto700Bar from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/HydraulicUpto700Bar.jpg'
import imgHoseFittingForApplication from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/HoseFittingForApplication.jpg'
import imgCompatibleAccessories from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/CompatibleAccessories.jpg'
import imgPtfeHoses from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/PtfeHoses.jpg'
import imgMetalHoseHighTemp from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/MetalHoseHighTemp.jpg'
import IndustrialHose from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/IndustrialHose.jpg'



function ExtremelyHighPerformanceHoses() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const ExtremelyHigh1 = {
        image: imgOilAndGas,
        title: 'Extremely High-Pressure Hoses for the Oil and Gas Industry',
        description: 'Thermoplastic high-pressure hoses are very common in the oil and gas industry. In addition to high resistance to chemicals, thermoplastic hoses exhibit low volumetric expansion and are resistant to external pressure. They are typically used for transporting gas and nitrogen and for the injection of chemicals and greases.',
    };

    const ExtremelyHigh2 = {
        image: imgApplication,
        title: 'Extremely High-Pressure Hoses for Hydraulic Applications',
        description: 'Extremely high-pressure hoses for hydraulic applications are used, for example, for controlling hydraulic components, bolt tightening tools, and torque application, as well as in instrumentation devices. HANSA-FLEX extremely high-pressure hydraulic hoses combine great strength with flexibility.',
        listItems: ["Lightweight and flexible", "High resistance to load", "Resistant to aging, ozone, and UV light"]
    };

    const ExtremelyHigh3 = {
        image: imgwaterJet,
        title: 'Extremely High-Pressure Hoses for Water Jet Applications',
        description: 'High-pressure water jet is used for sewer and pipe cleaning, as well as for general surface cleaning. Other important applications include water jet cutting and the renovation of concrete surfaces. HANSA-FLEX offers a specially developed range of extremely high-pressure hoses for pressures up to 3,200 bar.',
        listItems: ["High resistance to load", "Highly abrasion-resistant outer hose cover results in a long service life", "High resistance to impulse load"]
    };

    const ExtremelyHigh4 = {
        image: imghoseProfessional,
        description: "You can rely on the usual high-quality products and services from the well-trained personnel at HANSA-FLEX, who can also advise you on the choice of material, dimensions, accessories, and commissioning of your systems.",
        listItems: ["Complete range of production machinery and equipment", "Customer-specific manufacturing by experienced specialists", "Quality control at various stages, including pressure tests"]
    };

    const certifiedQuality = [
        {
            title: "Available Variants",
            description: "Available with internal diameters from 3 mm to 25 mm, these hoses can be used for pressure ranges up to 4,000 bar",
            image: imgMark
        },
        {
            title: "Robust and Flexible",
            description: "Two to eight layers of wire braid, reinforced version and open spiraled layer.",
            image: imgMark
        },
        {
            title: "Customized Solutions",
            description: "Suitable fittings and accessories in any length",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgExeHigPresHose}
                    title={"EXTREMELY HIGH-PRESSURE HOSES FOR APPLICATIONS UP TO 3,200 BAR"}
                    paragraph={"HANSA-FLEX extremely high-pressure hoses are always the first choice in systems where high pressures prevail. Indeed, these may have to withstand pressures up to 4,000 bar in the oil and gas industry, water jet, hydraulic applications and in the automotive sector. HANSA-FLEX provides extremely high-pressure hoses specially tailored to your needs and supplied as ready-to-assemble hose lines."}
                //TODO button
                />
            </div>

            <div>
                <SectionHeader
                    title={"FIELDS OF APPLICATION OF HANSA-FLEX EXTREMELY HIGH-PRESSURE HOSES"}
                />
                <div>
                    <ImageText
                        data={ExtremelyHigh1} />

                    <ImageText
                        data={ExtremelyHigh2}
                        left={false} />

                    <ImageText
                        data={ExtremelyHigh3} />
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"CERTIFIED HIGH-PRESSURE HOSES OF HANSA-FLEX"}
                    subtitle={"High-quality thermoplastic hoses with wire-reinforced pressure supports impress with their quality, service life, and reliability."}
                />
                <FeatureCardCarousel
                    featuresData={certifiedQuality} />
            </div>

            <div>
                <SectionHeader
                    title={"EXTREMELY HIGH-PRESSURE HOSES FROM HOSE PROFESSIONALS"}
                    subtitle={"We know what is important when working with extremely high pressures. At HANSA-FLEX, the highest material and manufacturing quality is guaranteed."}
                />
                <ImageText data={ExtremelyHigh4} />
            </div>

            {/* TODO: TextPoster */}
            {/* TODO: Carousal */}

            <div>
                <SectionHeader
                    title={"THESE HANSA-FLEX PRODUCTS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHydraulicUpto700Bar} learnMoreLink={"/seven-hundred-hydraulics"}
                                title="Components for High-Pressure Hydraulics up to 700 bar"
                                desc={"HANSA-FLEX offers a selected range of products for heavy-duty hydraulics with pressures up to 700 bar and can supply customized components for up to 1,000 bar."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHoseFittingForApplication} learnMoreLink={"https://shop.hansa-flex.be/nl_NL/hose-fittings/c/webcat_HF_SAR"}
                                title="Connections for Hydraulic Applications"
                                desc={"HANSA-FLEX hose connections are designed to fit perfectly to hydraulic hoses. They also receive a zinc-nickel surface coating as standard."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgCompatibleAccessories} learnMoreLink={"/hose-accessories"}
                                title="Compatible Accessories for Hose Lines"
                                desc={"In addition to first-class hoses, the HANSA-FLEX accessory portfolio includes friction, heat, and torque protection devices, as well as safety equipment such as hose..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgPtfeHoses} learnMoreLink={"/Ptfe-hoses"}
                                title="PTFE hoses with high chemical resistance"
                                desc={"Due to their high chemical resistance to most media, HANSA-FLEX PTFE hoses are the first choice for all challenging applications."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgMetalHoseHighTemp} learnMoreLink={"/metal-hoses"}
                                title="Metal Hoses with High-Temperature Tolerance"
                                desc={"Metal hoses stand out, especially for their ability to withstand high mechanical loads, vacuum resistance, and high-temperature tolerance."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={IndustrialHose} learnMoreLink={"/Industrial-hoses"}
                                title="Highest Quality Industrial Hoses"
                                desc={"HANSA-FLEX can produce industrial hoses according to the individual needs of the customer quickly and competently in its specialized hose workshop."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );

}

export default ExtremelyHighPerformanceHoses;